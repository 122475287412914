<template>
    <div class="stage-inner-container-padded mt-2">
        <!-- Select Technician -->
        <div class="d-flex flex-column shadow mt-2">
            <div
                :class="`${(technicianSelected) ? 'bg-vai-success-lighter' : 'bg-info'} px-3 fs-1-2-5 font-weight-bold py-2 rounded-top`"
            >
                {{(technicianSelected) ? 'Selected Technician' : 'Select A Technician'}}
            </div>
            <div :class="`p-2 rounded-bottom border ${(taskInfoOkay) ? 'border-vai-success-lighter' : 'border-warning'}`">
                <tech-select
                    :parentFormID="entryID"
                    :callOnSelect="selectTech"
                ></tech-select>
            </div>
        </div>

        <!-- Parent Selection -->
        <div v-if="!parent" class="d-flex flex-column shadow mt-2">
            <div
                :class="`bg-vai-success-lighter px-3 fs-1-2-5 font-weight-bold py-2 rounded-top`"
            >
                Selected Parent {{"(" + parentType + ")"}}
            </div>
            <div :class="`pt-1 px-1 rounded-bottom border border-vai-success-lighter`">
                <div v-if="loadingParentData" class="w-100">
                    <wait-dots title="Loading Parent Information"></wait-dots>
                </div>
                <div v-else class="p-2">
                    <b-row>
                        <b-col v-for="item in parentDisplayItems" :key="item.id"
                            cols="12"
                            md="6"
                            xl="4"
                        >
                            <span class="font-weight-bold">
                                {{item.display + ': '}} 
                            </span>
                            <span>
                                {{item.content}}
                            </span>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
        <!-- Parent Display -->
        <!-- Task Information -->
        <div class="d-flex flex-column shadow mt-2">
            <div
                :class="`${(taskInfoOkay) ? 'bg-vai-success-lighter' : 'bg-warning'} px-3 fs-1-2-5 font-weight-bold py-2 rounded-top`"
            >
                Task Information
            </div>
            <div :class="`pt-1 px-1 rounded-bottom border ${(taskInfoOkay) ? 'border-vai-success-lighter' : 'border-warning'}`">
                <!-- Task Name -->
                <b-form-group
                    id="task-name-label"
                    description="A Brief Name For This Task"
                    label="Task Name"
                    label-for="task-name-inp"
                    :invalid-feedback="taskNameFeedback"
                    :state="taskNameState"
                >
                    <b-form-input id="task-name-inp" v-model="name" :state="taskNameState"></b-form-input>
                </b-form-group>

                <b-row>
                    <b-col sm="12" lg="6">
                        <!-- Event Date Selection -->
                        <b-form-group
                            id="task-date-label"
                            label="Event Date"
                            label-for="task-date-inp"
                            :state="eventDateState"
                            :invalid-feedback="'Event Date Is Required'"
                        >
                            <b-input-group>
                                <b-form-input id="task-date-inp" v-model="eventDate" type="date" :state="eventDateState" min="2000-01-01" max="2050-01-01"></b-form-input>
                                
                                <b-input-group-append>
                                    <b-button variant="info" @click="setEventDateToday">Today</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" lg="6">
                        <!-- Event Time Selection -->
                        <b-form-group
                            id="task-time-label"
                            label="Event Time"
                            label-for="task-time-inp"
                            :state="eventTimeState"
                            :invalid-feedback="'Event Time Is Required'"
                        >
                            <b-input-group>
                                <b-form-input id="task-time-inp" v-model="eventTime" type="time" :state="eventTimeState"></b-form-input>
                                
                                <b-input-group-append>
                                    <b-button variant="info" @click="setEventTimeNow">Now</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>

                <!-- Task Description -->
                <b-form-group
                    id="task-desc-label"
                    description="A Description For This Task"
                    label="Task Description"
                    label-for="task-desc-inp"
                >
                    <b-form-textarea id="task-desc-inp" v-model="description" rows="3"></b-form-textarea>
                </b-form-group>

                
            </div>
        </div>
       

        <!-- Photos Repeatable -->
        <div class="d-flex flex-column shadow mt-2">
            <div
                :class="`${(photosOkay) ? 'bg-vai-success-lighter' : 'bg-warning'} px-3 fs-1-2-5 font-weight-bold py-2 rounded-top`"
            >
                Photos
            </div>
            <div :class="`pt-1 px-1 py-2 rounded-bottom border ${(photosOkay) ? 'border-vai-success-lighter' : 'border-warning'}`">
                <div class="d-flex flex-row">
                    <camera-capture-or-file-upload
                        ref="photo-repeatable-new"
                        :whenDone="photoAdded"
                    ></camera-capture-or-file-upload>
                    <div
                        class="ml-2 d-flex flex-column flex-grow-1"
                    >
                        <div class="fs-0-8-5 mb-1">
                            Caption
                        </div>
                        <b-form-input size="sm" v-model="currentPhotoCaption"></b-form-input>
                        <b-form-text
                            aria-setsize="sm"
                        >
                            Describe This Photo
                        </b-form-text>
                    </div>
                </div>
                <div v-for="photo in photos" :key="photo.id" class="d-flex flex-row mt-2">
                    <div class="position-relative">
                        <hover-image-with-modal
                            :value="photo.id"
                            :displaySize="100"
                        ></hover-image-with-modal>
                        <div class="position-absolute delete-attachment-badge">
                            <b-badge 
                                pill 
                                variant="danger"
                                @click="removePhoto(photo.id)"
                            >
                                <b-icon-x-circle-fill
                                    font-scale="2"
                                    scale="0.85"
                                ></b-icon-x-circle-fill>
                            </b-badge>
                        </div>
                    </div>
                    <div
                        class="ml-2 mt-2 d-flex flex-column flex-grow-1"
                    >
                        <div class="fs-0-8-5 mb-1">
                            Caption
                        </div>
                        <b-form-input size="sm" v-model="photo.comment"></b-form-input>
                        <b-form-text
                            aria-setsize="sm"
                        >
                            Describe This Photo
                        </b-form-text>
                    </div>
                </div>
            </div>
        </div>
        <!-- Start of the Attachments section -->
        <div class="d-flex flex-column shadow mt-2">
            <div
                :class="`${(attachmentsOkay.passed) ? 'bg-vai-success-lighter' : 'bg-warning'} px-3 fs-1-2-5 font-weight-bold py-2 rounded-top`"
            >
                Attachments
            </div>
            <div :class="`pt-1 px-1 pb-2 rounded-bottom border ${(attachmentsOkay.passed) ? 'border-vai-success-lighter' : 'border-warning'}`">
                <div class="d-flex flex-row">
                    <!-- Need to find out where the attachments "paperclip icon" is used and then use that button just like the camera-capture "div/input" below -->
                    <!-- Could we just import the UploadFile block that is under the Hide Attachments button? -->
                    <!-- Needs work -->
                    <upload-file-drop
                        ref="attachment-repeatable-new"
                        :uploadCallback="attachmentAdded"
                        :collapseOnSmallScreen="true"
                        :hideUploadConfirmation="true"
                        acceptsText="Accepts Most Types"
                        :allowReset="true"
                        :useEmit="false"
                        :includeFileAPIResponse="true"
                    >
                    </upload-file-drop>
                    <div class="d-flex flex-column flex-grow-1">
                        <div class="ml-2 d-flex flex-column">
                            <div class="fs-0-8-5 mb-1">
                                Attachment Type
                            </div>
                            <b-input-group 
                                id="attachment-type-grp"
                                size="sm"
                            >
                                <b-form-select
                                    v-model="currentAttachmentType"
                                    :options="attachmentTypes"
                                    aria-describedby="attachment-type-required"
                                ></b-form-select>
                                <template #append>
                                    <b-button
                                        @click="openCreateNewAttachmentType()"
                                        variant="success"
                                    >
                                        <b-icon-plus-circle-fill></b-icon-plus-circle-fill> New
                                    </b-button>
                                </template>
                            </b-input-group>
                        </div>
                        <div
                            class="ml-2 mt-2 d-flex flex-column"
                        >
                            <div class="fs-0-8-5 mb-1">
                                Description
                            </div>
                            <b-form-input size="sm" v-model="currentAttachmentDesc"></b-form-input>
                        </div>
                    </div>
                    
                </div>
                <div v-for="(attachment, index) in attachments" :key="attachment.id" class="d-flex flex-row mt-2">
                    <div class="position-relative">
                        <b-icon-file-earmark-image
                            font-scale="5" class="text-dark"
                        ></b-icon-file-earmark-image>
                        <div class="position-absolute delete-attachment-badge">
                            <b-badge 
                                pill 
                                variant="danger"
                                @click="removeAttachment(attachment.id)"
                            >
                                <b-icon-x-circle-fill
                                    font-scale="2"
                                    scale="0.85"
                                ></b-icon-x-circle-fill>
                            </b-badge>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-grow-1">
                        <div class="ml-2 d-flex flex-column">
                            <div class="fs-0-8-5 mb-1">
                                Attachment Type
                            </div>
                            <b-input-group 
                                id="attachment-type-grp"
                                size="sm"
                            >
                                <b-form-select
                                    v-model="attachment.type"
                                    :options="attachmentTypes"
                                    :aria-describedby="`file-attachment-type-required-${attachment.id}`"
                                ></b-form-select>
                                <template #append>
                                    <b-button
                                        @click="openCreateNewAttachmentType(index)"
                                        variant="success"
                                    >
                                        <b-icon-plus-circle-fill></b-icon-plus-circle-fill> New
                                    </b-button>
                                </template>
                            </b-input-group>
                            <b-form-text v-if="!attachment.type" 
                                :id="`file-attachment-type-required-${attachment.id}`" 
                                text-variant="danger"
                            >
                                Attachment Type Is Required
                            </b-form-text>
                        </div>
                        <div
                            class="ml-2 mt-2 d-flex flex-column"
                        >
                            <div class="fs-0-8-5 mb-1">
                                Description
                            </div>
                            <b-form-input size="sm" v-model="attachment.comment"></b-form-input>
                        </div>
                        <div
                            class="ml-2 mt-2 d-flex flex-column"
                        >
                            <div class="fs-0-8-5 mb-1">
                                File Name
                            </div>
                            <b-input-group size="sm" :append="`.${attachment.fileExt}`">
                                <b-form-input
                                    v-model="attachment.fileName"
                                    :aria-describedby="`file-name-dot-warning-${attachment.id} file-name-required-${attachment.id}`"
                                ></b-form-input>
                            </b-input-group>
                            <b-form-text v-if="!!attachment.fileExt && attachment.fileName.includes('.')" 
                                :id="`file-name-dot-warning-${attachment.id}`" 
                                text-variant="vai-danger-dark"
                            >
                                The file extension of {{`.${attachment.fileExt}`}} is already on the file.
                            </b-form-text>
                            <b-form-text v-if="!attachment.fileName" 
                                :id="`file-name-required-${attachment.id}`" 
                                text-variant="danger"
                            >
                                File Name Is Required
                            </b-form-text>
                        </div>
                        
                    </div>
                        
                </div>
            </div>
        </div>
        <!-- Start of the Signature Block section -->
        <div class="d-flex flex-column shadow mt-2">
            <div
                :class="`${(signature != null) ? 'bg-vai-success-lighter' : 'bg-info'} px-3 fs-1-2-5 font-weight-bold py-2 rounded-top`"
            >
                Technician Signature
            </div>
            <div :class="`pt-1 px-1 pb-2 rounded-bottom border ${(signature != null) ? 'border-vai-success-lighter' : 'border-info'}`">
                <div
                    class="mt-2 d-flex flex-column"
                >
                    <signature-capture
                        :whenDone="signatureCompleted"
                        :hideCertificationStatement="true"
                    ></signature-capture>
                </div>
            </div>
        </div>
        <!-- Start of the Signature Block section -->
        <div class="d-flex flex-column shadow mt-2">
            <div
                :class="`${(customerSignature != null) ? 'bg-vai-success-lighter' : 'bg-info'} px-3 fs-1-2-5 font-weight-bold py-2 rounded-top`"
            >
                Customer Signature
            </div>
            <div :class="`pt-1 px-1 pb-2 rounded-bottom border ${(customerSignature != null) ? 'border-vai-success-lighter' : 'border-info'}`">
                <div
                    class="mt-2 d-flex flex-column"
                >
                    <signature-capture
                        :whenDone="customerSignatureCompleted"
                        :hideCertificationStatement="true"
                    ></signature-capture>
                </div>
            </div>
        </div>
        <!-- Save And Discard Section -->
        <div class="mb-5 mt-3 px-3 d-flex flex-column">
            <div v-if="!photosOkay || !attachmentsOkay.passed || !taskInfoOkay || !eventTimeState || !eventDateState" class="text-danger">
                Cannot Save With Errors: 
            </div>
            <div v-if="!taskInfoOkay" class="ml-2 pt-1 text-danger fs-0-8-5"> - Task Name Is Required</div>
            <div v-if="!eventDateState" class="ml-2 pt-1 text-danger fs-0-8-5"> - Event Date Is Required</div>
            <div v-if="!eventTimeState" class="ml-2 pt-1 text-danger fs-0-8-5"> - Event Time Is Required</div>
            <div v-if="!photosOkay" class="ml-2 pt-1 text-danger fs-0-8-5"> - Photos Require Attention</div>
            <div v-for="failure in attachmentsOkay.failedFor" :key="failure" class="ml-2 pt-1 text-danger fs-0-8-5">
                - {{failure}}
            </div>
            <b-button
                class="mt-2"
                variant="success"
                @click="packageForSave"
                block
                :disabled="resultEventID != null || !photosOkay || !attachmentsOkay.passed || !taskInfoOkay || !eventTimeState || !eventDateState"
            >
                {{(resultEventID != null)? 'Already Saved' : 'Save'}}
            </b-button>
            <b-button
                variant="danger" 
                @click="discardTask"
                block
                :disabled="resultEventID != null"
            >
                Discard
            </b-button>
        </div>

        <b-modal
            id="ask-for-attachment-type"
            title="Create New Attachment Type"
            v-model="creatingNewAttachmentType"
            hide-footer
        >
            <div class="p-2 mb-2 bg-vai-gray-200 shadow">
                <label for="new-attachment-type-name" class="mt-3">Attachment Type Name:</label>
                <b-form-input
                    id="new-attachment-type-name"
                    v-model="newAttachmentTypeName"
                    :state="checkNewAttachmentTypeUnique"
                    placeholder="Attachment Type Name"
                    aria-describedby="new-attachment-type-must-be-unique"
                ></b-form-input>
                <b-form-invalid-feedback 
                    id="new-attachment-type-must-be-unique"
                >
                    New Attachment Type Name Must Be Unique
                </b-form-invalid-feedback>
                <label for="new-attachment-type-desc" class="mt-2">Attachment Type Description:</label>
                <b-form-textarea
                    id="new-attachment-type-desc"
                    rows="2"
                    max-rows="6"
                    v-model="newAttachmentTypeDesc"
                    placeholder="Attachment Type Description (Optional)"
                ></b-form-textarea>
                <div class="my-1 mt-2">
                    <div class="d-flex flex-row justify-content-end">
                        <b-button
                            class="mr-2"
                            variant="warning"
                            @click="creatingNewAttachmentType = false"
                        > Cancel
                        </b-button>
                        <b-button
                            class="mr-1"
                            variant="success"
                            :disabled="checkNewAttachmentTypeUnique != null || newAttachmentTypeName == null || newAttachmentTypeName == ''"
                            @click="saveNewAttachmentType(newAttachmentTypeFillsDefault, newAttachmentTypeFillsAttachmentPos)"
                        > Create New Attachment Type
                        </b-button>
                    </div>
                </div>
            </div>

        </b-modal>
        <b-modal :id="`save-modal-${entryID}`" v-model="showSavingModal"
            centered
            :no-close-on-esc="true"
            :no-close-on-backdrop="true"
            :hide-header-close="true"
            :ok-only="true"
            :ok-disabled="waiting"
            title="Saving Generic Task Entry"
        >
            <div v-if="waiting">
                <wait-dots title="Submitting..."></wait-dots>
            </div>
            <div v-else-if="savingHasError">
                <div class="d-flex flex-column">
                    <div class="text-center fs-1-5 text-danger">
                        An Error Occured While Saving <br>
                        <small>Status Code: {{saveErrorStatusCode}}</small>
                    </div>
                    <div>
                        <hr>
                    </div>
                    <div v-for="error in errorsOnSave" :key="error">
                        {{error}}
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="d-flex flex-column">
                    <div class="text-center fs-1-5 text-success">
                        Saved Succesfully
                    </div>
                    <div>
                        <hr>
                    </div>
                    <b-button
                        block
                        variant="info"
                        @click="openThisGenericTask"
                    >
                        View This Generic Task Entry
                    </b-button>
                    <b-button
                        block
                        variant="info"
                        @click="openParentForm"
                    >
                        View Parent {{parentType}}
                    </b-button>
                    <b-button
                        block
                        variant="info"
                        @click="$router.go(-1)"
                    >
                        Exit Generic Task Entry
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = butils.underscore;
const uuidv4 = butils.uuidv4;

import techSelection from '@/components/subcomponents/TechnicianSelection.vue'
import sigCapture from '@/components/subcomponents/SignatureCapture.vue'
import photoCapture from '@/components/subcomponents/PhotoCapture.vue'
import image from '@/components/subcomponents/Image.vue'
import waitDots from '@/components/subcomponents/WaitingDots.vue'
// Importing UploadFileDrop to use as an extention of the AttachmentsList maybe?
// Beautiful mental model - TR
import UploadFileDrop from '@/components/UploadFileDrop.vue'

export default {
    name: 'GenericWorkOrderTaskEntry', 
    components:{
        'tech-select': techSelection,
        'signature-capture': sigCapture,
        'camera-capture-or-file-upload': photoCapture,
        'hover-image-with-modal': image,
        'upload-file-drop': UploadFileDrop,
        'wait-dots': waitDots
    },
    props:{
        parentID: String
    },
    data(){
        return{
            waiting: false,
            loadingParentData: false,
            showSavingModal: false,
            savingHasError: false,
            saveErrorStatusCode: null,
            errorsOnSave: [],
            resultEventID: null,
            entryID: null,
            parent: null,
            name: '',
            eventDate: null,
            eventTime: null,
            description: '',
            rawTechData: null,
            currentPhotoCaption: null,
            currentAttachmentDesc: null,
            photos: [],
            // Adding attachments array
            attachments: [],
            signature: null, 
            customerSignature: null,         
            // Requirements For The Generic Task Entry
            formDef:{
                meta: {
                    name: 'Generic Task Entry',
                    title: 'Generic Task Entry',
                    formParentKey: null
                },
                fields: {}
            },
            creatingNewAttachmentType: false,
            newAttachmentTypeName: null,
            newAttachmentTypeDesc: '',
            rawAttachmentTypes: [],
            attachmentTypes:[],
            currentAttachmentType: null,
            newAttachmentTypeFillsDefault: true,
            newAttachmentTypeFillsAttachmentPos: -1,
            photosOkay: true,
            briefDescriptions: {
                device: null,
                connection: null,
                site: null,
                customer: null
            },
            parentDisplayItems: [],
            parentType: '',
            rawParentType: null
            // These are associated with the functions clearSignature & uploadSignature need to figure out how to implement the actions of the buttons
            // saved: false,
            // uploading: false,
        }
    },
    methods:{
        setEventTimeNow(){
            var d = new Date();
            var hours = d.getHours().toString()
            var mins = d.getMinutes().toString()
            this.eventTime = `${hours.padStart(2, "0")}:${mins.padStart(2, "0")}`
        },
        setEventDateToday(){
            var d = new Date();
            var year = d.getFullYear().toString();
            var month = (d.getMonth()+1).toString();
            var day = d.getDate().toString();
            this.eventDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
        },
        openCreateNewAttachmentType(index = undefined){
            if(index != undefined){
                this.newAttachmentTypeFillsDefault = false;
                this.newAttachmentTypeFillsAttachmentPos = index;
            }else{
                this.newAttachmentTypeFillsDefault = true;
                this.newAttachmentTypeFillsAttachmentPos = -1;
            }
            this.creatingNewAttachmentType = true;
        },
        selectTech(techData){
            this.rawTechData = techData;
        },
        photoAdded(photoFileID, capturedInField){
            this.photos.push({
                id: photoFileID,
                comment: this.currentPhotoCaption
            })
            this.$refs["photo-repeatable-new"].resetPhotoCaptured();
            this.$nextTick(()=>{
                this.$refs["photo-repeatable-new"].saved = false;
            })
            this.currentPhotoCaption = null;
        },
        removePhoto(photoID){
            this.$bvModal.msgBoxConfirm(`This action will permanately delete this photo. Are You Sure?`, {
                title: 'Confirm Photo Deletion',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'I Meant What I Said, Do It!',
                cancelTitle: 'Nevermind',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if(value){
                    this.photos = _.filter(this.photos, (photo)=>{ return photo.id != photoID });
                }
            })
            .catch(err => {
                console.log(err)
            })
            
        },
        attachmentAdded(attachmentFileID, uploadFileResponse){
            console.log(attachmentFileID)
            console.log(uploadFileResponse)
            var attachmentFileName = uploadFileResponse.uploadInfo.uploadFileName.split('.').slice(0, -1).join('.');
            var attachmentFileExt = uploadFileResponse.original.link.split('.').slice(-1).join('.');
            this.attachments.push({
                id: attachmentFileID,
                comment: this.currentAttachmentDesc,
                type: this.currentAttachmentType,
                fileName: attachmentFileName,
                fileExt: attachmentFileExt,
            })
            this.$refs["attachment-repeatable-new"].resetForUpload();
            this.$nextTick(()=>{
                this.$refs["attachment-repeatable-new"].uploadCompleted = false;
            })
            this.currentAttachmentDesc = null;
        },
        removeAttachment(attachmentID){
             this.$bvModal.msgBoxConfirm(`This action will permanately delete this attachment. Are You Sure?`, {
                title: 'Confirm Attachment Deletion',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'I Meant What I Said, Do It!',
                cancelTitle: 'Nevermind',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if(value){
                    this.attachments = _.filter(this.attachments, (attachment)=>{ return attachment.id != attachmentID });
                }
            })
            .catch(err => {
                console.log(err)
            })
            
        },
        saveTask(taskDef){
            this.errorsOnSave = [];
            this.savingHasError = false;
            this.saveErrorStatusCode = null;
            this.waiting = true;
            this.showSavingModal = true;
            console.log(taskDef)
            butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/generic_task_entries/create`, taskDef)
            .then((response)=>{
                this.waiting = false;
                this.resultEventID = response.data.result.id;
            })
            .catch((err)=>{
                butils.apiRequestErrorHandling(err, this, 'Create Generic Task Entry', true, this.handleSaveGeneralError)
                this.waiting = false;
            })
        },
        handleSaveGeneralError(err){
            this.savingHasError = true;
            if(_.has(err, 'response')){
                var resp = err.response;
                if(_.has(resp, 'status')){
                    this.saveErrorStatusCode = resp.status;
                    var errAry = _.get(resp, ['data', 'errors']);
                    if(errAry != undefined){
                        errAry.forEach((errorMsg)=>{
                            this.errorsOnSave.push(errorMsg);
                        })
                    }else{
                        this.errorsOnSave.push('An Unexpected Error Type Has Occured')
                        this.errorsOnSave.push('Please Take A Screenshot Of The Following Error Data and Contact Support')
                        this.errorsOnSave.push(resp.toString())
                    }
                }else{
                    this.saveErrorStatusCode = "UNKNOWN STATUS CODE"
                    this.errorsOnSave.push('An Unknown Error Has Occured')
                    this.errorsOnSave.push('Please Take A Screenshot Of The Following Error Data and Contact Support')
                    this.errorsOnSave.push(resp.toString())
                }
            }else{
                this.saveErrorStatusCode = "UNKNOWN STATUS CODE"
                this.errorsOnSave.push('An Unknown Error Has Occured')
                this.errorsOnSave.push('Please Take A Screenshot Of The Following Error Data and Contact Support')
                this.errorsOnSave.push(err.toString())
            }
        },
        packageForSave(){
            var error = !this.photosOkay || !this.attachmentsOkay.passed || !this.taskInfoOkay || !this.eventTimeState || !this.eventDateState;
            if(!error){
                var packed = {
                    parentID: this.parentID,
                    technician: this.rawTechData.id,
                    name: this.name,
                    eventDate: this.eventDate,
                    eventTime: this.eventTime,
                    description: this.description,
                    photos: this.photos.map((photo, idx)=>{ return { ...photo, index: idx }; }),
                    attachments: this.attachments.map((attachment, idx)=>{ return { ...attachment, index: idx } }),
                    signature: this.signature,
                    customerSignature: this.customerSignature,
                };
                this.saveTask(packed);
            }else{
                butils.createToast(this, "Cannot Save Task", "Form has one or more errors", 'danger', 10);
            }
        },
        discardTask(){
            console.log("Discard Placeholder")
            this.$router.go(-1);
        },
        signatureCompleted(signatureID){
            this.signature = signatureID;
        },
        customerSignatureCompleted(customerSignatureID){
            this.customerSignature = customerSignatureID;
        },
        getAttachmentTypes(){
            this.attachmentTypes = [];
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/lists/attachment_types`)
            .then((response)=>{
                var raw = response.data.result; // Array of attachment types
                this.rawAttachmentTypes = raw;
                raw.forEach(element => {
                   this.attachmentTypes.push({ text: `${element.name} (${element.note})`, value: element.id})
                });
            }) 
            .catch((err)=>{
                butils.apiRequestErrorHandling(err, this, 'Fetch Attachment Types', false)
            })
        },
        saveNewAttachmentType(fillDefault = true, fillNumber = -1){
            var packed = {
                name: this.newAttachmentTypeName,
                note: this.newAttachmentTypeDesc
            }
            butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/atils/attachment_types`, packed)
            .then((response)=>{
                if(fillDefault){
                    this.currentAttachmentType = response.data.result.attacment_type_id;
                }else if(fillNumber != -1){
                    this.attachments[fillNumber].type = response.data.result.attacment_type_id;
                }
                this.getAttachmentTypes();
                this.creatingNewAttachmentType = false;
            }) 
            .catch((err)=>{
                console.log(err)
                butils.apiRequestErrorHandling(err, this, 'Create New Attachment Type', false)
            })
        },
        fetchParentData(parentType){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/${parentType}/${this.parentID}.json?dref=true`)
            .then((response)=>{
                this.loadingParentData = false;
                let parentData = response.data.result.records[0];
                console.log(parentData)
                if(parentType == 'devices'){
                    this.parentType = "Device"
                    this.parentDisplayItems.push({
                        display: "Model",
                        content: `${(parentData.device_size != null) ? parentData.device_size.toString() + '" ' : ''}${(parentData.device_model != null) ? parentData.device_model.toString() + ' ' : ''}`
                    })
                    this.parentDisplayItems.push({
                        display: "Serial",
                        content: parentData.device_serial
                    })
                    this.parentDisplayItems.push({
                        display: "Location",
                        content: parentData.device_location
                    })
                    this.parentDisplayItems.push({
                        display: "Hazard",
                        content: parentData.hazard_type
                    })
                    this.parentDisplayItems.push({
                        display: "Notes",
                        content: parentData.device_comments
                    })
                }else if(parentType == 'connections'){
                    this.parentType = "Connection"
                    this.parentDisplayItems.push({
                        display: "Meter Number",
                        content: parentData.meter_number
                    })
                    this.parentDisplayItems.push({
                        display: "Meter Size",
                        content: parentData.meter_size
                    })
                    this.parentDisplayItems.push({
                        display: "Location ID",
                        content: parentData.location_id
                    })
                    this.parentDisplayItems.push({
                        display: "Utility",
                        content: parentData.utility
                    })
                    this.parentDisplayItems.push({
                        display: "Service Type",
                        content: parentData.service_type
                    })
                    this.parentDisplayItems.push({
                        display: "Connection Type",
                        content: parentData.connection_type
                    })
                }else if(parentType == 'sites'){
                    this.parentType = "Site"
                    this.parentDisplayItems.push({
                        display: "Address",
                        content: parentData.formatted_address
                    })
                    this.parentDisplayItems.push({
                        display: "Name",
                        content: parentData.site_name
                    })
                    this.parentDisplayItems.push({
                        display: "Type",
                        content: parentData.site_type
                    })
                    this.parentDisplayItems.push({
                        display: "Contact",
                        content: parentData.site_contact_name
                    })
                    this.parentDisplayItems.push({
                        display: "Notes",
                        content: parentData.notes
                    })
                }else if(parentType == 'customers'){
                    this.parentType = "Customer"
                    this.parentDisplayItems.push({
                        display: "Address",
                        content: parentData.formatted_address
                    })
                    this.parentDisplayItems.push({
                        display: "Name",
                        content: parentData.customer_name
                    })
                    this.parentDisplayItems.push({
                        display: "Contact",
                        content: `${parentData.first_name} ${parentData.last_name}`
                    })
                    this.parentDisplayItems.push({
                        display: "Notes",
                        content: parentData.notes
                    })
                }

                this.parentDisplayItems.forEach((item)=>{
                    item.id = uuidv4();
                })
            })
            .catch((err)=>{
                console.log(err)
                butils.apiRequestErrorHandling(err, this, 'Fetch Parent Detailed Data', false)
            })
        },
        getUserDefaultFormLink(formInspects, parentID){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: formInspects});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + parentID
            }
        },
        openThisGenericTask(){
            this.$router.push({ path: `/home/generictask/${this.resultEventID}` })
        },
        openParentForm(){
            let formPath = this.getUserDefaultFormLink(this.rawParentType, this.parentID)
            this.$router.push({ path: formPath })
        }
    },
    watch:{

    },
    computed:{
        eventTimeState(){
            return this.eventTime != null;
        },
        eventDateState(){
            return this.eventDate != null;
        },
        taskNameState(){
            return this.name.length >= 3;
        },
        taskNameFeedback(){
            if(this.name.length < 3){
                return "Task Name Must Be At Least 3 Characters"
            }else{
                return "Task Name Is Required"
            }
        },
        technicianSelected(){
            return this.rawTechData != null;
        },
        checkNewAttachmentTypeUnique: function(){
            var match = _.find(this.rawAttachmentTypes, (at)=>{ return at.name.localeCompare(this.newAttachmentTypeName, 'en', { sensitivity: 'base' }) == 0 })
            if(match == undefined){
                return null;
            }else{
                return false;
            }
        },
        attachmentsOkay: function(){
            var passed = true;
            var failedFor = [];
            this.attachments.forEach((attachment, idx)=>{
                // Each Attachment Requires
                // Attachment Type
                if(attachment.type == null || attachment.type == undefined){
                    passed = false;
                    failedFor.push(`Attachment Type Is Required - Missing On Attachment ${idx+1}`)
                }else{
                    let match = _.find(this.rawAttachmentTypes, (at)=>{ return at.id == attachment.type; });
                    if(match == undefined){
                        passed = false;
                        failedFor.push(`Attachment Type Must Be Valid - Invalid On Attachment ${idx+1}`)
                    }
                }
                // A File Name
                if(attachment.fileName == undefined || attachment.fileName == null || attachment.fileName.trim() == ''){
                    passed = false;
                    failedFor.push(`Attachment File Name Is Required - Missing On Attachment ${idx+1}`)
                }

                // A File Extension
                if(attachment.fileExt == null || attachment.fileExt == undefined || attachment.fileExt.trim() == ''){
                    passed = false;
                    failedFor.push(`Attachment Extension Is Required - Missing On Attachment ${idx+1}`)
                }
            })

            return {
                passed: passed,
                failedFor: failedFor
            }
        },
        taskInfoOkay: function(){
            return this.taskNameState;
        }
    },
    beforeCreate(){

    },
    created(){
        if(this.parentID != null && this.parentID != undefined){
            this.loadingParentData = true;
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/atils/breadcrumbs/${this.parentID}.json`)
            .then((response)=>{
                let breadcrumb = response.data.result;
                let parentType = breadcrumb.id_is;
                this.rawParentType = breadcrumb.id_is;

                switch (parentType) {
                    case 'devices':
                        this.briefDescriptions.device = breadcrumb.device_title;
                    case 'connections':
                        this.briefDescriptions.connection = breadcrumb.connection_title;
                    case 'sites':
                        this.briefDescriptions.site = breadcrumb.site_title;
                    case 'customers':
                        this.briefDescriptions.customer = breadcrumb.customer_title;
                    default:
                        this.fetchParentData(parentType);
                        break;
                }
            })
            .catch((err)=>{
                butils.apiRequestErrorHandling(err, this, 'Fetching Parent Data', false)
                console.log(err)
            })
            
        }
        
    },
    beforeMount (){
        this.getAttachmentTypes();
        this.entryID = butils.uuid();
        this.$store.commit('initForm',{ localFormID: this.entryID, meta: this.formDef.meta });
    },
    mounted(){
        
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){
        this.$store.commit('clearForm', {localFormID: this.entryID});
    },
    destroyed(){

    }
}
</script>

<style scoped>
.delete-attachment-badge{
    top: 0;
    right: 0;
}
</style>