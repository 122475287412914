<template>
    <div>
        <b-row>
            <b-col cols="12">
                <!-- Technician Select -->
                <api-select
                    ref="techapiselect"
                    :dataName="'technician_id'"
                    :inputLabel="'Technician'"
                    :apiEndpoint="'/technicians/choices.json?order=alpha'"
                    :required="true"
                    :parentFormID="parentFormID"
                    :value="value"
                    @onTouch="techChanged" 
                ></api-select>
            </b-col>
        </b-row>
        <b-row no-gutters v-if="technician.selected">
            <b-col sm="4" md="4" lg="3" xl="3">
                <!-- Empty Spacer -->
            </b-col>
            <b-col sm="8" md="8" lg="9" xl="9">
                <b-row no-gutters>
                    <b-col sm="12" md="6" lg="4"  class="text-left">
                        <span class="f-label">Technician:</span>
                        <span class="f-value">{{technician.first + ' ' + technician.last}}</span>
                    </b-col>

                    <b-col sm="12" md="6" lg="4"  class="text-left">
                        <span class="f-label">Company:</span>
                        <span class="f-value">{{technician.company}}</span>                        
                    </b-col>

                    <b-col v-if="technician.email[0] != null" sm="12" md="6" lg="4"  class="text-left">
                        <span class="f-label">E-mail:</span>
                        <span class="f-value">{{technician.email[0]}}</span>                        
                    </b-col>

                    <b-col v-if="technician.phone[0] != null" sm="12" md="6" lg="4"  class="text-left">
                        <span class="f-label">Phone:</span>
                        <span class="f-value">{{technician.phone[0]}}</span>                        
                    </b-col>

                    <b-col :class="(technician.certs.backflow_tester.num == null) ? 'bg-warning' : ''" sm="12" md="6" lg="4"  class="text-left">
                        <span class="f-label">Cert #:</span>
                        <span class="f-value">{{technician.certs.backflow_tester.num}}</span>                        
                    </b-col>


                    <b-col :class="(technician.certs.backflow_tester.date == null || !certDateValid) ? 'bg-vai-danger-med' : ''" sm="12" md="6" lg="4"  class="text-left">
                        <span class="f-label">Cert Date:</span>
                        <span class="f-value">{{technician.certs.backflow_tester.date}}</span>                        
                    </b-col>

                    <b-col :class="(technician.testKit.model == null) ? 'bg-warning' : ''" sm="12" md="6" lg="4"  class="text-left">
                        <span class="f-label">Test Kit:</span>
                        <span class="f-value">{{testKitModel}}</span>                        
                    </b-col>

                    <b-col :class="(technician.testKit.serial == null) ? 'bg-warning' : ''" sm="12" md="6" lg="4"  class="text-left">
                        <span class="f-label">Test Kit Serial:</span>
                        <span class="f-value">{{technician.testKit.serial}}</span>                        
                    </b-col>

                    <b-col :class="(testKitCalDate == null || !testKitCalDateValid) ? 'bg-vai-danger-med' : ''" sm="12" md="6" lg="4"  class="text-left">
                        <span class="f-label">Test Kit Cal Date:</span>
                        <span class="f-value">{{testKitCalDate}}</span>                      
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = require('underscore');

import APISelect from '@/components/FormSelectFetchAPIInput2.vue'
import dayjs from 'dayjs';

export default {
    name: 'techselection',
    components:{
        'api-select': APISelect
    },
    props:{
        parentFormID: String,
        callOnSelect: Function,
    },
    data(){
        return{
            value: null,
            technician: {
                selected: false,
                id: null,
                first: null,
                last: null,
                company: null,
                email: [null],
                phone: [null],
                certifications: {
                    treeo: {
                        num: null,
                        date: null
                    }
                },
                certs: {
                    backflow_tester: {
                        num: null,
                        date: null
                    }
                },
                testKit: {
                    manufacturer: null,
                    model: null,
                    serial: null,
                    calDate: null
                }
            },
        }
    },
    methods:{
        techChanged(dataName){
            // Called Exclusively From The Technician Field
            var fieldValue = this.$store.getters.getFormValue({parentFormID: this.parentFormID, dataName: dataName});
            // Clear the technician value
            this.technician = {
                selected: false,
                id: null,
                first: null,
                last: null,
                company: null,
                email: [null],
                phone: [null],
                certifications: {
                    treeo: {
                        num: null,
                        date: null
                    }
                },
                certs: {
                    backflow_tester: {
                        num: null,
                        date: null
                    }
                },
                testKit: {
                    manufacturer: null,
                    model: null,
                    serial: null,
                    calDate: null
                }
            };
            this.technician.id = fieldValue;
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/technicians/${fieldValue}`)
            .then(async (response) => {
                if(response.data.result.records.length == 1){
                    var tech = response.data.result.records[0];
                    // Update Techncician Information In Model
                    this.technician.selected = true;
                    this.technician.first = tech.tech_first_name;
                    this.technician.last = tech.tech_last_name;
                    this.technician.company = tech.tech_company;
                    if(tech.tech_email != null){
                        this.technician.email = tech.tech_email;
                    }else{
                        this.technician.email = [null]
                    }
                    if(tech.tech_phone != null){
                        this.technician.phone = tech.tech_phone;
                    }else{
                        this.technician.phone = [null]
                    }
                    if(_.has(tech.certifications, 'treeo')){
                        this.technician.certifications.treeo.num = tech.certifications.treeo.num;
                        this.technician.certifications.treeo.date = tech.certifications.treeo.date;
                    }else{
                        this.technician.certifications = {treeo: {num: null,date: null}};
                    }

                    if(_.has(tech.certs, 'backflow_tester')) {
                        this.technician.certs.backflow_tester.num = tech.certs.backflow_tester.num;
                        this.technician.certs.backflow_tester.date = tech.certs.backflow_tester.date;
                    } else {
                        this.technician.certs.backflow_tester = {num: null, date: null};
                    }

                    this.technician.testKit.manufacturer = tech.test_kit_manufacturer;
                    this.technician.testKit.model = tech.test_kit_model;
                    this.technician.testKit.serial = tech.test_kit_serial;
                    this.technician.testKit.calDate = tech.test_kit_cal_date;

                    // Generate Tech Full Name
                    var techName = this.technician.first + ' ' + this.technician.last;

                    // Update Technician Fields
                    this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: 'formtextregexinput', dataName: 'tech_name', value: techName, valid: true, isDirty: true} );
                    this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: 'formtextregexinput', dataName: 'cert_number', value: this.technician.certs.backflow_tester.num, valid: true, isDirty: true} );
                    this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: 'formtextregexinput', dataName: 'cert_date', value: this.technician.certs.backflow_tester.date, valid: true, isDirty: true} );
                    this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: 'formtextregexinput', dataName: 'test_kit_sn', value: this.technician.testKit.serial, valid: true, isDirty: true} );
                    this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: 'formtextregexinput', dataName: 'test_klt_cal_date', value: this.technician.testKit.calDate, valid: true, isDirty: true} );
                    
                    this.callOnSelect(this.technician);
                }else{
                    butils.createToast(this, "Failed To Fetch Technician Info", "Technician Information Could Not Be Retrieved Or Was Not Retrieved", 'danger')
                }
            })
            .catch((error) => {
                butils.createToast(this, "Failed To Fetch Technician Info", "Technician Information Could Not Be Retrieved Or Was Not Retrieved", 'danger');
                console.log(error);
            });

        }
    },
    watch:{

    },
    computed:{
        testKitModel: function(){
            var mdl = '';
            if(this.technician.testKit.manufacturer != null){
                mdl += this.technician.testKit.manufacturer + ' ';
            }
            if(this.technician.testKit.model != null){
                mdl += this.technician.testKit.model;
            }
            return mdl
        },
        testKitCalDate: function(){
            var date = null;
            if(this.technician.testKit.calDate != null){
                date = new Date(this.technician.testKit.calDate).toISOString().substr(0,10);
            }
            return date;
        },        
        testKitCalDateValid: function(){            
            if(this.technician.testKit.calDate != null) {
                var dd = new dayjs(this.technician.testKit.calDate);
                var validDate = dd.add(1, 'year');
                return dd.isValid() && validDate.isAfter(new Date());
            }
            return false;
        },
        certDateValid: function(){
            
            if(this. technician.certs.backflow_tester.date != null) {
                var dd = new dayjs(this.technician.certs.backflow_tester.date);
                var validDate = dd.add(2, 'year');
                return dd.isValid() && validDate.isAfter(new Date());
            }
            return false;
        }
    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){
        this.value = this.$store.getters.userTechnicianID;
        if(this.value != null){
            this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: 'formselectapifetchinput', dataName: "technician_id",  value: this.value, valid: true, isDirty: true} );
            this.techChanged("technician_id")
        }
    },
    mounted(){
        
        
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>