<template>
<b-form-group 
    :id="'fieldset-horizontal-label-'+ parentFormID + '-' + index + '-' + dataName"
    label-cols-sm="4"
    label-cols-lg="3"
    description=""
    :label="inputLabel"
    :label-size="size"
    :label-for="'input-horizontal-input-'+ parentFormID + '-' + index + '-' + dataName"
    :invalid-feedback="invalidFeedback"
    :state="validationResult.all"
>
    <b-input-group class="p-inputgroup">
        <template v-if="(prependComponent != null)" v-slot:prepend>
            <component 
                :is="prependComponent"
                :touch="prependInput"
                :size="size"
            ></component>
        </template>
                
        <AutoComplete forceSelection
            ref="autoCompleteComponent"
            field="text" 
            v-model="autoCompleteValue" 
            
            :required="required"
            :state="validationResult.all"
            :readonly="readOnly"
            :multiple="multiSelect"
            :select-size="count"
            :size="size"
            :dropdown="true"
            :suggestions="filteredOptions" 
            
            @item-select="autoCompleteSelect($event)"
            @complete="searchValues($event)" 
            @dropdown-click="searchValues($event)"
            @focus="onFocus($event)" />            
        
        <template v-if="(appendComponent != null)" v-slot:append>
            <component 
                :is="appendComponent"
                :touch="appendInput"
                :size="size"
            ></component>
        </template>
    </b-input-group>
  
</b-form-group > 
    
</template>

<script>
const cloneDeep = require('lodash.clonedeep');
const axios = require('axios');

const butils = require('../libs/basicUtils.js')
const _ = butils.underscore;

const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });

import Fuse from 'fuse.js'

import MicroArrayAddButton from '@/components/microcomponents/ArrayItemAddButton.vue'
import MicroArrayRemoveButton from '@/components/microcomponents/ArrayItemRemoveButton.vue'
import AutoComplete from 'primevue/autocomplete';
import { auto } from 'async';

export default {
    name: 'formselectapifetchinput',
    components:{
        'btn-add': MicroArrayAddButton,
        'btn-remove': MicroArrayRemoveButton,
        'AutoComplete': AutoComplete
    },
    props:{
        dataName: String,
        inputLabel: String,
        apiEndpoint: String,
        parentFormID: String,
        size: {
            type: String,
            validator: (val) =>{
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].indexOf(val) != -1
            },
            default: () => {return "md"}
        },
        multiSelect: {
            type: Boolean,
            default: () => {return false}
        },
        value: {
            type: [String, Array],
            default: (val) => {
                if(typeof val == "string")
                    return "";
                else
                    return [];
                }
        },
        blankIsNull: {
            type: Boolean,
            default: () => {return false} 
        },
        required: {
            type: Boolean,
            default: () => {return false} 
        },
        readOnly: {
            type: Boolean,
            default: () => {return false} 
        },
        index: {
            type: Number,
            default: () => {return -1;}
        },
        appendComponent: {
            type: String,
            default: () => {return null} 
        },
        prependComponent: {
            type: String,
            default: () => {return null} 
        },
        displayCount: {
            type: Number,
            default: () => {return 1;} 
        }
    },
    watch: { 
      	apiEndpoint: function(newVal, oldVal) {
            this.setupAndFetchChoices();
        }
    },
    data(){
        var fuse = null;
        return{
            autoCompleteValue: null,
            inputValue: null,
            originalValue: null,
            options: [],
            isDirty: false,
            count: 1,
            searchTerm: null,
            showSearchDropDown: false,
            searchResults: [],            
            filteredOptions: [],
            activelyAboveSearchIndex: -1,
            ownUniqueID: butils.uuidv4()
        }
    },
    methods: {
        onFocus(event) {              
            this.$refs.autoCompleteComponent.onDropdownClick(event);
        },
        autoCompleteSelect(event) {                     
            this.inputValue = event.value.id;            
            this.revertSearch();
		},
        searchValues(event) {
            console.log(event);

            var term = event.query;

            if(term == null || term == ""){
                var found = _.map(this.options, (item)=>{ return {item: item}});
            }else{                
                var found = this.fuse.search(term);
            }
            
            this.filteredOptions = _.map(found, (f) => { return {id: f.item.value, text: f.item.text} });
		},
        revertSearch(){
            var found = _.find(this.options, (opt)=>{ return opt.value == this.inputValue; })
            if(found != undefined){
                this.searchTerm = found.text;
            }
            this.showSearchDropDown = false;
            this.activelyAboveSearchIndex = -1;
            this.touch();
        },
       
        touch() {
            this.isDirty = true;
            this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.getStorableValue(), valid: this.validationResult.all, isDirty: this.isDirty} );
            this.$emit('onTouch', this.dataName);
        },
        appendInput(){
            if(this.appendComponent == 'btn-add'){
                // we are adding an item
                this.$store.commit('formAppend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
                this.inputValue = "";
                this.isDirty = false;
                this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.getStorableValue(), valid: this.validationResult.all, isDirty: this.isDirty} );
            }else if(this.appendComponent == 'btn-remove'){
                // We are removing an item
                this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index} );
                this.$store.commit('formAppend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
            }else{
                this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.getStorableValue(), valid: this.validationResult.all, isDirty: this.isDirty} );
            }
        },
        prependInput(){
            this.$store.commit('formPrepend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
        },
        getStorableValue(){
            if(this.blankIsNull){
                if(typeof this.inputValue == 'array' && this.inputValue.length == 0){
                    return null;
                }else if(this.inputValue == "" || this.inputValue == ''){
                    return null;
                }else{
                    return this.inputValue
                }
            }else{
                return this.inputValue;
            }
        },
        setupAndFetchChoices(){
             if(this.multiSelect){
                if(this.displayCount == 1){
                    this.count = 4;
                }else{
                    this.count = this.displayCount;
                }
            }
            
            // FETCH API
            instance.get(process.env.VUE_APP_API_BASE_URL + '' + this.apiEndpoint)
                .then(async (response) => {
                    //console.log("API Fetch Select Received Response For API Endpoint Passed");
                    //console.log(process.env.VUE_APP_API_BASE_URL + '' + this.apiEndpoint);
                    //console.log(response.data.result);
                    
                    this.options = response.data.result.records;
                    if(_.isArray(this.value)){
                        if(this.value.length > 0){
                            if(butils.validate.isUUID(this.value[0]) || this.value[0] == null){
                                
                            }else{
                                // This is a sting value
                                var matches = []
                                this.value.forEach((val)=>{
                                    var match = _.find(this.options, (opt)=>{ return opt.text == val; })
                                    if(match != undefined){
                                        matches.push(match);
                                    }
                                })
                                this.originalValue = cloneDeep(matches);
                                this.inputValue = matches;
                            }
                        }else{
                            // Empty Array, just leave it alone
                            this.originalValue = cloneDeep(this.value);
                            this.inputValue = this.value;
                        }
                    }else{
                        // Not an array of values
                        if(butils.validate.isUUID(this.value) || this.value == null){
                            this.originalValue = cloneDeep(this.value);
                            this.inputValue = this.value;
                        }else{
                             // This is a sting value
                            var match = _.find(this.options, (opt)=>{ return opt.text == this.value; })
                            if(match != undefined){
                                this.originalValue = cloneDeep(match.value);
                                this.inputValue = match.value;
                            }else{
                                this.value = null;
                                this.originalValue = cloneDeep(null);
                                this.inputValue = null;
                            }
                        }
                    }
                    // Create the fuser

                    const fuseOptions = {
                        // isCaseSensitive: false,
                        // includeScore: false,
                        // shouldSort: true,
                        // includeMatches: false,
                        // findAllMatches: false,
                        minMatchCharLength: 1,
                        //location: 0,
                        threshold: 0.2,
                        // distance: 100,
                        useExtendedSearch: true,
                        // ignoreLocation: false,
                        // ignoreFieldNorm: false,
                        // fieldNormWeight: 1,
                        keys: ['text']
                    };

                    this.fuse = new Fuse(this.options,fuseOptions)
                    // Populate The Search Term
                    var found = _.find(this.options, (opt)=>{ return opt.value == this.inputValue; })
                    if(found != undefined){
                        this.searchTerm = found.text;
                    }

                    this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.getStorableValue(), valid: this.validationResult.all} );
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    computed: {
        // a computed getter
        validationResult: function () {
            // if the search term is filled, it MUST be a value which matches the input otherwise, ALWAYS mark as bad
            if(this.searchTerm != null && this.searchTerm != ''){
                var found = _.find(this.options, (opt)=>{ return opt.text == this.searchTerm; })
                if(found != undefined){
                    if(this.inputValue == found.value){
                        match = true;
                    }else{
                        match = false;
                    }
                }else{
                    match = false;
                }
            }else{
                var results = this.options.filter(elm => elm.value == this.inputValue);
                var match = true;
                if( (results == null || results.length == 0) && this.required)
                    match = false;
            }
           
            // var regexRes = ( (!this.required) ? this.validator.test(this.inputValue) || this.inputValue.length == 0 : this.validator.test(this.inputValue) );
            var requiredRes = ( ((this.required && this.inputValue == null) || (this.required && this.inputValue.length == 0)) ? false : true) ;

            return {matchOptions: match, requirementFilled: requiredRes, all: (match && requiredRes)};
        },
        invalidFeedback(){
            var res = this.validationResult;
            if(res.all)
                return ''
            else if(!res.requirementFilled)
                return 'Required'
            else if(!res.matchOptions)
                return 'Invalid Entry: Not One Of The Available Options'
            else
                return 'OK'
        }
    },   
    mounted(){
        this.$watch('searchResults', (newValue, oldvalue) => {});
    },
    created(){
        
    },
    beforeDestroy(){
        if(this.index == -1){
            this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index} );
        }
        this.fuse = null;
    },
    beforeMount (){
       this.setupAndFetchChoices();
    },
}
</script>

<style scoped>
.small-screen-fake-modal{
    background-color: rgba(0, 0, 0, 0.25);
    width: 100vw;
    height: 100vh;
}
.fake-modal-inner{
    width: 90vw;
    max-height: 90vh;
}
.drop-down-for-select-search{
    position: absolute;
    z-index: 10;
    width: 98%;
    max-height: 200px;
    color: black;
    overflow-y: scroll;
    display: block;
}
.drop-down-for-tiny-screen-modal{
    overflow-y: scroll;
}
.drop-down-for-tiny-screen-modal:nth-child(even){
    background-color: #92dbd2;
}
.search-li{
    color:black;
    z-index: 100;
}

.search-li:hover{
    background-color: #92dbd2;
    cursor: pointer;
}

/* This is just a testing with box-shadows to add depth to the modal selection */
/* .shadow-box{
    box-shadow: 3px 3px red, -1em 0 .4em black;
} */
</style>


