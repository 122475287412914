<template>
    <div>
        <div v-show="!saved && !uploading">
             <label :for="`upload-photo-${ownID}`" class="mb-0">
                <b-button 
                    variant="info"
                    @click="attemptButtonProxy"
                >
                    <i class="material-icons large-camera-icon text-dark">photo_camera</i>
                    <br>
                    <span class="text-dark">Add Photo</span>
                </b-button>
            </label>
            <input v-show="false" @input="fileAdded" :id="`upload-photo-${ownID}`" type="file" class="custom-form-file" accept="image/*" capture :ref="`uploadElement${ownID}`">
            <b-button 
                v-if="allowAttemptReupload"
                variant="warning"
                @click="uploadPhoto"
                :disabled="uploading"
            >
                <i class="material-icons large-camera-icon">autorenew</i>
                <br>
                <span>Retry Upload</span>
            </b-button>
        </div>
        <div v-if="uploading">
            <b-img v-if="displayTemp != null" :src="displayTemp" fluid class="tmp-reasonable-max"></b-img>
            <div class="d-flex justify-content-center align-items-center mt-4 overlay-top">
                <b-spinner small type="grow" class="mr-1" variant="vai-main-grey"></b-spinner>
                <b-spinner type="grow" variant="vai-main-orange"></b-spinner>
                <b-spinner small type="grow" class="ml-1" variant="vai-main-grey"></b-spinner>
            </div>
            <div class="d-flex justify-content-center h3 mt-4">
                <span>{{uploadStatus}}</span>
            </div>
            <b-progress :max="loadingMax" height="2rem">
                <b-progress-bar :value="currentUploadProgress">
                    <span>Progress: <strong>{{ currentUploadProgress.toFixed(2) }} / {{ loadingMax }}</strong></span>
                </b-progress-bar>
            </b-progress>
        </div>
        <div v-if="saved">
            <hover-image-with-modal
                :imgID="fileID"
            ></hover-image-with-modal>
        </div>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = butils.underscore;
const uuidv4 = butils.uuidv4;

import UploadFileDrop from '@/components/UploadFileDrop.vue'
import Image from '@/components/subcomponents/Image.vue';

export default {
    name: 'PhotoCapture',
    components:{
        'file-upload': UploadFileDrop,
        'hover-image-with-modal': Image
    },
    props:{
        whenDone: {
            type: Function
        },
        knownFileID: {
            type: String,
            default: ()=>{ return null; }
        }
    },
    data(){
        return{
            ownID: uuidv4(),
            fileID: null,
            file: null,
            photoURL: false,
            photoReady: false,
            saved: false,
            allowAttemptReupload: false,
            displayTemp: null,
            uploadStatus: null,
            showUploadBar: false,
            currentUploadProgress: 0,
            loadingMax: 100,
            uploading: false,
            uploadRetryAuto: 0,
        }
    },
    methods:{
        attemptButtonProxy(){
            var elem = this.$refs[`uploadElement${this.ownID}`];
            elem.click();
        },
        fileAdded(event){
            if(event.target.files && event.target.files[0]){
                var reader = new FileReader();
                reader.onload = (e)=>{
                    this.displayTemp = e.target.result;
                }
                reader.readAsDataURL(event.target.files[0]);
                this.file = event.target.files[0];
                this.photoURL = URL.createObjectURL(event.target.files[0]);
                this.photoReady = true;
                this.uploadPhoto();
            }
            
        },
        uploadPhoto(){
            if(this.file == null){
                butils.createToast(this, "Cannot Approve Empty Photo","How did you do this anyways? Call us or something so we can make this error go away.","danger", 5);
            }else{
                this.uploading = true;
                var formData = new FormData();
                formData.append("file", this.file);
                butils.customInstance.fileUpload(240000).post(`${process.env.VUE_APP_FILE_API_BASE_URL}/upload`, formData, {
                    onUploadProgress: progressEvent => {
                        let percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100)
                        if(this.uploadRetryAuto > 0){
                            this.uploadStatus = `(Auto Retry ${this.uploadRetryAuto + 1}/4) Uploading Photo ${butils.formatters.requestSizeToHumanReadableSize(progressEvent.total)}`;
                        }else{
                            this.uploadStatus = `Uploading Photo ${butils.formatters.requestSizeToHumanReadableSize(progressEvent.total)}`;
                        }
                        this.showUploadBar = true;
                        this.currentUploadProgress = percentCompleted;
                    }
                })
                .then((response)=>{
                    this.allowAttemptReupload = false;
                    this.uploading = false;
                    this.fileID = response.data.result.fileID;
                    this.whenDone(this.fileID, true);
                    this.saved = true;
                })
                .catch((err)=>{
                    this.uploading = false;
                    if(butils.isError401(err)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    }else if(butils.isError403(err)){
                        butils.createToast(this, 'Photo Upload Disallowed By Endpoint Control', 'Contact your administrator to receive permission to perform file upload', 'warning');
                    }else{
                        console.log(err);
                        if(this.uploadRetryAuto <= 2){
                            this.uploadRetryAuto++;
                            this.uploadPhoto();
                        }else{
                            butils.createToast(this, 'An Error Occured Durring Photo Upload', 'Attempted To Upload Three Times. Was Unsuccessful every time. Typically this is because your device has a poor data connection. Attempt reupload manually by pressing the "Attempt Reupload" button when network connectivity is restored. If the problem persists, contact support', 'danger');
                            this.allowAttemptReupload = true;
                            this.uploadRetryAuto = 0;
                        }
                        
                    }
                })
            }
        },
        fileUploadComplete(fileID){
            this.fileID = fileID;
            this.whenDone(this.fileID, false);
            this.saved = true;
            
        },
        dataURIToBlob(dataURI){
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], {type:mimeString});
        },
        startPhoto(){
        },
        attemptPhotoTake(){
            this.$refs.camCanvas.width = this.$refs.liveCamera.videoWidth;
            this.$refs.camCanvas.height = this.$refs.liveCamera.videoHeight;
            this.$refs.camCanvas.getContext('2d').drawImage(this.$refs.liveCamera, 0, 0, this.$refs.liveCamera.videoWidth, this.$refs.liveCamera.videoHeight);
            this.currentImgDataURL = this.$refs.camCanvas.toDataURL('image/jpeg');
            this.photoCaptured = true;
            this.$refs.liveCamera.width = 0;
        },
        resetPhotoCaptured(){
            if(_.has(this.$refs.camCanvas, "height")){
                this.$refs.camCanvas.height = 0;
            }
            this.currentImgDataURL = null;
            this.photoCaptured = false;
            this.saved = false;
        },
        setupPhotoUpload(){
            this.showPhotoUpload = true;
        }
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        if(this.knownFileID != null){
            this.photoCaptured = true;
            this.saved = true;
            this.showPhotoUpload = true;
            this.fileID = this.knownFileID;
        }else{
            // const supported = 'mediaDevices' in navigator;
            // if(supported){
            //     navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: "environment" } }, audio: false })
            //     .then((stream)=>{
            //         this.$refs.liveCamera.srcObject = stream;
            //         this.startedPhotoTaking = true;
            //     })
            //     .catch((err)=>{
            //         console.log(`Error Occured While Attempting To Access Device Camera`);
            //         console.log(err);
            //         this.setupPhotoUpload();
            //     })
            // }else{
            //     // Needs to have file upload box instead
            //     this.setupPhotoUpload();
            // }
        }
        
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
.tmp-reasonable-max{
    max-height: 256px;
    max-width: 256px;
}
.large-camera-icon{
    font-size: 3rem;
}
#camera-capture-canvas,
#camera-stream{
    width: 100%;
    max-width: 500px;
}
.signature-container {
    max-width: 500px;
    height: max(50vw, 200px);
    max-height: 200px;
    background-color:#f8f9fa;
}
.signature-button-container{
    max-width: 500px;
}
.signature-canvas-desc{
    max-width: 500px;
}
</style>