<template>
    <div>
        <b-overlay :show="saved || uploading" rounded="sm" class="signature-button-container">
            <template #overlay>
                <div v-if="saved" class="text-center">
                    <i class="material-icons large-lock-icon">lock</i>
                    <br>
                    <span>Signature Saved</span>
                </div>
                <div v-if="uploading" class="d-flex justify-content-center align-items-center mt-4">
                    <b-spinner small type="grow" class="mr-1" variant="vai-main-grey"></b-spinner>
                    <b-spinner type="grow" variant="vai-main-orange"></b-spinner>
                    <b-spinner small type="grow" class="ml-1" variant="vai-main-grey"></b-spinner>
                </div>
                <div v-if="uploading" class="text-center d-flex justify-content-center">
                    <span>Saving Signature</span>
                </div>
            </template>
            <div v-if="!hideCertificationStatement" class="signature-canvas-desc">
                <span>I hereby certify that this data, 
                    replacement <small>(if applicable)</small>,
                    and test result <small>(if applicable) </small> 
                    is accurate and reflects the proper operation 
                    and mantainence of the assembly.
                </span>
            </div>
            <div class="d-flex flex-column">
                    <div class="flex-grow-1 signature-container">
                        <VueSignaturePad
                            ref="techSignature"
                        ></VueSignaturePad>
                    </div>
                    <div class="signature-button-container d-flex flex-row">
                        <div class="flex-fill">
                            <b-button block variant="warning" :disabled="saved && !uploading" @click="clearSignature">Clear</b-button>
                        </div>
                        <div class="flex-fill">
                            <b-button block variant="success" :disabled="saved && !uploading" @click="uploadSignature">Save Signature</b-button>
                        </div>
                    </div>
            </div>
        </b-overlay>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');

export default {
    name: 'SignatureCapture',
    components:{

    },
    props:{
        whenDone: {
            type: Function
        },
        knownFileID: {
            type: String,
            default: ()=>{ return null; }
        },
        hideCertificationStatement:{
            type: Boolean,
            default: ()=>{ return false; }
        }
    },
    data(){
        return{
            fileID: null,
            uploading: false,
            saved: false
        }
    },
    methods:{
        onEnd(evt){
            console.log(`Signature - End Event`)
            console.log(evt)
        },
        uploadSignature(){
            if(this.$refs.techSignature.isEmpty()){
                butils.createToast(this, "Cannot Save Empty Signature","Signature is required.","danger", 5);
            }else{
                this.uploading = true;
                var sigData = this.$refs.techSignature.saveSignature();
                var blob = this.dataURIToBlob(sigData.data);
                var formData = new FormData();
                formData.append("file", blob);
                butils.customInstance.fileUpload(240000).post(`${process.env.VUE_APP_FILE_API_BASE_URL}/upload`, formData)
                .then((response)=>{
                    this.uploading = false;
                    this.fileID = response.data.result.fileID;
                    this.$refs.techSignature.lockSignaturePad();
                    this.whenDone(this.fileID);
                    this.saved = true;
                })
                .catch((err)=>{
                    this.uploading = false;
                    if(butils.isError401(err)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    }else if(butils.isError403(err)){
                        butils.createToast(this, 'Signature Upload Disallowed By Endpoint Control', 'Contact your administrator to receive permission to perform file upload', 'warning');
                    }else{
                        console.log(err);
                        butils.createToast(this, 'An Error Occured Durring Signature Upload', 'Try uploading again, if the problem persists, contact support', 'danger');
                    }
                })
            }
        },
        dataURIToBlob(dataURI){
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], {type:mimeString});
        },
        clearSignature(){
            if(this.saved == false && this.uploading == false){
                this.$refs.techSignature.clearSignature();
            }
        }
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        if(this.knownFileID != null){
            this.saved = true;
        }
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
.large-lock-icon{
    font-size: 3rem;
}
.signature-container {
    max-width: 500px;
    height: max(50vw, 200px);
    max-height: 200px;
    background-color:#f8f9fa;
}
.signature-button-container{
    max-width: 500px;
}
.signature-canvas-desc{
    max-width: 500px;
}
</style>